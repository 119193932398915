import { useState } from "react";

const productsData = [
  {
    id: 1,
    tabName: "Toll Optimized Routes",
    title: "Toll Optimized Routes",
    subtitle:
      "Cost & time trade-off-based cheapest vs fastest and other routes",
    imageUrl:
      "https://cdn.tollguru.com/tollguru_v2/images/assets/landing-page-images/toll-optimization-platform/1-toll-optimized-routes-01.webp",
  },
  {
    id: 2,
    tabName: "Tolls on Map",
    title: "Tolls on Map",
    subtitle:
      "Details of all the toll plazas along the routes, along with toll charges for the different toll payment options",
    imageUrl:
      "https://cdn.tollguru.com/tollguru_v2/images/assets/landing-page-images/toll-optimization-platform/2-tolls-on-map-01.webp",
  },
  {
    id: 3,
    tabName: "Vehicle Specific Tolls",
    title: "Vehicle Specific Tolls",
    subtitle:
      "Vehicle specific toll pricing for truck, car, taxi, RV, EV, etc. based on axle-count, height, weight and other parameters",
    imageUrl:
      "https://cdn.tollguru.com/tollguru_v2/images/assets/landing-page-images/toll-optimization-platform/3-vehicle-specific-tolls-01.webp",
  },
  {
    id: 4,
    tabName: "IFTA Miles & Toll Miles",
    title: "IFTA Miles & Toll Miles",
    subtitle: "State mileage and toll miles reports for compliance",
    imageUrl:
      "https://cdn.tollguru.com/tollguru_v2/images/assets/landing-page-images/toll-optimization-platform/4-IFTA-miles-&-toll-miles-01.webp",
  },
  {
    id: 5,
    tabName: "Truck Routing",
    title: "Truck Routing & GPS Navigation",
    subtitle:
      "Truck-legal routes with parking, fuel, truck stops, and live GPS navigation",
    imageUrl:
      "https://cdn.tollguru.com/tollguru_v2/images/assets/landing-page-images/toll-optimization-platform/5-truck-routing-&-GPS-navigation-01.webp",
  },
];

function ProductsTabbedWindow() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (index) => {
    setActiveTab((prev) => index);
  };

  const handleNext = () => {
    setActiveTab((prev) => {
      if (prev === productsData.length - 1) return prev;
      return prev + 1;
    });
  };

  const handlePrev = () => {
    setActiveTab((prev) => {
      if (prev === 0) {
        return prev;
      }
      return prev - 1;
    });
  };

  return (
    <>
      <div className="w-[100%] lg:grid grid-cols-12 gap-x-6 gap-y-6 lg:gap-x-12 xl:gap-x-24 ">
        <div className="col-span-12 hidden lg:col-span-3 lg:flex flex-col justify-start items-center">
          <div className="flex flex-col gap-4 w-full">
            {productsData.map((product, index) => {
              return (
                <div
                  className={`flex items-center text-tollguru-text p-4 hover:cursor-pointer border border-gray-200 rounded-md ${
                    productsData[activeTab].tabName === product.tabName
                      ? "bg-gray-100 drop-shadow-md"
                      : ""
                  }`}
                  onClick={() => handleTabChange(index)}>
                  <svg
                    stroke="currentColor"
                    fill={
                      productsData[activeTab].tabName === product.tabName
                        ? "#1da1f2"
                        : "currentColor"
                    }
                    stroke-width="0"
                    viewBox="0 0 16 16"
                    color="#D1D5DB"
                    height="8"
                    width="8"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      color: `${
                        productsData[activeTab].tabName === product.tabName
                          ? "blue"
                          : "rgb(209, 213, 219)"
                      }`,
                      marginRight: "8px",
                      marginTop: "-2px",
                    }}>
                    <circle cx="8" cy="8" r="8"></circle>
                  </svg>
                  <label className="hover:cursor-pointer font-medium text-tollguru-heading" htmlFor={product.id.toString()}>
                    {product.tabName}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-span-12 lg:col-span-9 relative">
          <div className="absolute h-auto w-auto -inset-4 top-2 -left-4 -right-4 bg-[#FBFBFC] -z-[1] rounded-3xl overflow-hidden drop-shadow-md" />
          <div className="col-span-8 border bg-white border-gray-200 rounded-2xl overflow-hidden">
            <div id="macWindow" className="w-[100%] h-[100%]">
              <div
                id="windowTitleBar"
                className="w-[100%] h-[56px] text-center transition bg-gradient-to-t from-tollguru-primary to-tollguru-secondary text-white flex items-center gap-3 pl-5">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 16 16"
                  color="#F9FAFB"
                  style={{ color: "#F9FAFB" }}
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg">
                  <circle cx="8" cy="8" r="8"></circle>
                </svg>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 16 16"
                  color="#F9FAFB"
                  style={{ color: "#F9FAFB" }}
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg">
                  <circle cx="8" cy="8" r="8"></circle>
                </svg>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 16 16"
                  color="#F9FAFB"
                  style={{ color: "#F9FAFB" }}
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg">
                  <circle cx="8" cy="8" r="8"></circle>
                </svg>
              </div>
              <div className="carousel-root w-full relative">
                <div className="carousel carousel-slider m-0 overflow-hidden flex relative w-full">
                  <div
                    id="windowContentBox"
                    className="slider-wrapper w-full relative h-auto flex flex-row justify-start items-center  m-auto overflow-hidden">
                    {productsData.map((product, index) => (
                      <>
                        <div
                          className="min-w-[100%] flex flex-col items-center"
                          style={{
                            transform: `translateX(-${
                              productsData.findIndex(
                                (p) => p.id === activeTab + 1
                              ) * 100
                            }%)`,
                            transition: "transform 0.5s ease",
                          }}>
                          <div className="max-w-[80%]">
                            <h3 className="text-center text-xl font-medium lg:text-2xl text-tollguru-heading mt-4 mb-2">
                              {product.title}
                            </h3>
                            <p className="text-center text-lg text-tollguru-text">
                              {product.subtitle}
                            </p>
                          </div>
                          <img
                            className="max-w-[100%] lg:max-w-[75%]"
                            src={product.imageUrl}
                            alt="tollguru-features"
                          />
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center mt-8 gap-4 lg:hidden">
          <button
            className="rounded-full bg-slate-100 p-3"
            onClick={handlePrev}>
            <div className="">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 16 16"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"></path>
              </svg>
            </div>
          </button>

          <button
            className="rounded-full bg-slate-100 p-3"
            onClick={handleNext}>
            <div className="">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 16 16"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"></path>
              </svg>
            </div>
          </button>
        </div>
      </div>
    </>
  );
}

export default ProductsTabbedWindow;
