import React from "react";
import { useState } from "react";
import { Tabs } from "../react/common/Tabs";
import TollCalc from "@components/tg-toll-calculator.astro";

import { Video } from "../components/ui/Video";
import ProductsTabbedWindow from "./ProductsTabbedWindow";

function CheckGradient() {
  return (
    <svg
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      className={`max-h-5 max-w-5`}>
      <defs>
        <linearGradient
          id="checkCircleGradient"
          x1="0%"
          y1="0%"
          x2="100%"
          y2="100%">
          <stop offset="0%" stop-color="tollguru-primary-blue"></stop>
          <stop offset="100%" stop-color="tollguru-secondary"></stop>
        </linearGradient>
      </defs>
      <circle
        cx="256"
        cy="256"
        r="248"
        fill="url(#checkCircleGradient)"></circle>
      <path
        fill="white"
        d="M227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
    </svg>
  );
}

const features = [
  {
    title: "US Toll Calculator",
    link: "https://tollguru.com/toll-calculator",
  },
  {
    title: "Europe Toll Calculator",
    link: "https://tollguru.com/toll-calculator-europe",
  },
  {
    title: "India Toll Calculator",
    link: "https://tollguru.com/toll-calculator-india",
  },
  {
    title: "Asia Toll Calculator",
    link: "https://tollguru.com/toll-calculator-asia",
  },
  {
    title: "UK Toll Calculator",
    link: "https://tollguru.com/toll-calculator-united-kingdom",
  },
  {
    title: "France Toll Calculator",
    link: "https://tollguru.com/toll-calculator-france",
  },
  {
    title: "Canada Toll Calculator",
    link: "https://tollguru.com/toll-calculator-canada",
  },
  {
    title: "Italy Toll Calculator",
    link: "https://tollguru.com/italy-toll-calculator",
  },
  {
    title: "Brazil Toll Calculator",
    link: "https://tollguru.com/toll-calculator-brazil",
  },
  {
    title: "Australia Toll Calculator",
    link: "https://tollguru.com/toll-calculator-australia",
  },

  {
    title: "Mexico Toll Calculator",
    link: "https://tollguru.com/toll-calculator-mexico",
  },
  {
    title: "Spain Toll Calculator",
    link: "https://tollguru.com/toll-calculator-spain",
  },
  {
    title: "Indonesia Toll Calculator",
    link: "https://tollguru.com/toll-calculator-indonesia",
  },
  {
    title: "Turkey Toll Calculator",
    link: "https://tollguru.com/toll-calculator-turkey",
  },
  {
    title: "Poland Toll Calculator",
    link: "https://tollguru.com/toll-calculator-poland",
  },
  {
    title: "Argentina Toll Calculator",
    link: "https://tollguru.com/toll-calculator-argentina",
  },
];

function TollCalculators() {
  return (
    <div className="relative min-h-72 flex items-center">
      <Video
        className="rounded-md w-full h-full object-cover absolute inset-0"
        icon="https://cdn.tollguru.com/tollguru_v2/video/landing/tollguru-toll-calculator-in-action-video.webm"
        iconMp4="https://cdn.tollguru.com/tollguru_v2/video/see-toll-calculator-in-action/mp4/tollguru-toll-calculator-in-action-video.mp4"
      />

      <div className="absolute h-full inset-0 bg-black opacity-60 z-10 rounded-xl" />
      <div className="relative z-10 inset-0 max-h-[50vh] max-w-screen-xl mx-auto overflow-y-scroll hide-scrollbar">
        <div className="p-5 grid grid-cols-2 md:grid-cols-2 my-4 lg:my-6 gap-6 md:gap-6">
          {features.map((item) => (
            <a href={item.link}>
              <div className="flex-shrink-0 relative px-5 py-2 overflow-hidden border shadow-md place-items-center rounded-md bg-tollguru-light-alt-2 hover:bg-text-gradient shadow-gray-900/20 hover:text-white hover:border-transparent group">
                <p className="block font-sans antialiased leading-snug tracking-normal text-md text-slate-600 group-hover:text-white text-center">
                  {item.title}
                </p>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

const TollCalculator = () => {
  const links = [
    { id: 1, name: "Toll Optimized Routes", url: "#" },
    { id: 2, name: "Tolls on Maps", url: "#" },
    { id: 3, name: "IFTA Mile", url: "#" },
    { id: 4, name: "Toll Miles", url: "#" },
    { id: 5, name: "Commercial Routing", url: "#" },
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-0 lg:gap-16">
      <div className="md:w-[90%] flex flex-col items-center justify-center p-0 md:p-8 md:gap-8 gap-2 md:order-2">
        <h3 className="text-xl lg:text-3xl bg-text-gradient text-transparent bg-clip-text font-semibold">
          <span className="bg-text-gradient text-transparent bg-clip-text">
            Save upto 30% with Toll
          </span>
          <span className="Save upto 30% with Toll"> Calculator in Action</span>
        </h3>
        <ul className="w-[100%] list-none flex flex-col justify-center md:flex md:flex-col gap-1 md:gap-3">
          {links.map((link) => (
            <div className="flex justify-start items-center gap-4">
              <CheckGradient />
              <li key={link.id} className="text-tollguru-text text-lg">
                {link.name}
              </li>
            </div>
          ))}
        </ul>
      </div>

      <div className="">
        <TollCalculators />
      </div>
    </div>
  );
};

function ProductsTabbedView() {
  return (
    <>
      <div
        className="mt-12 md:mt-24"
        role="banner"
        aria-label="Comprehensive Toll Optimization Platform"
        tabIndex={0}>
        <h2 className="text-3xl lg:text-4xl text-tollguru-heading font-bold lg:tracking-tight text-left md:text-center">
          Comprehensive Toll Optimization Platform
        </h2>
        <div className="mx-auto max-w-screen-lg">
          <p className="text-lg mt-4 text-tollguru-text text-left md:text-center">
            Thousands of trucking, last-mile delivery, rideshare and other
            fleets save in tolls with TollGuru!
          </p>
          {/* <p className="text-lg text-tollguru-text text-left md:text-center">
            Compare multiple routes for cost and time trade-offs, know the
            tolls, fuel costs, IFTA miles, toll miles and drive on the cheapest
            & compliant routes with HAZMAT, height, weight, axles & other
            restrictions.
          </p> */}
        </div>
      </div>

      <div className="my-8 md:my-16">
        <div className="w-full mx-auto flex flex-col gap-4">
          {/* <div className="text-xl">
            <Tabs
              mobileView={"row"}
              lineView={true}
              tabElements={products}
              selectedIndex={activeTab}
              setSelectedIndex={(index) => setActiveTab(index)}
            />
          </div> */}

          {/* <TollCalculator /> */}
          <ProductsTabbedWindow />

          {/* {products[activeTab] === "Toll Calculator" && <TollCalculator />}
          {products[activeTab] === "Toll API" && <TollCalculator />} */}
        </div>
      </div>
    </>
  );
}

export default ProductsTabbedView;
