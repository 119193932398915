---
// @ts-ignore
import { Video } from "./ui/Video";
const { nomargin } = Astro.props;

const features = [
  {
    title: "US Toll Calculator",
    link: "https://tollguru.com/toll-calculator",
  },
  {
    title: "Europe Toll Calculator",
    link: "http://localhost:4321/toll-calculator-europe",
  },
  {
    title: "Australia Toll Calculator",
    link: "https://tollguru.com/toll-calculator-australia",
  },
  {
    title: "Latin America Toll Calculator",
    link: "https://tollguru.com/toll-calculator-latin-america",
  },
  {
    title: "Asia Toll Calculator",
    link: "https://tollguru.com/toll-calculator-asia",
  },
  {
    title: "India Toll Calculator",
    link: "https://tollguru.com/toll-calculator-india",
  },
  {
    title: "Car Toll Calculator",
    link: "https://tollguru.com/car-toll-calculator",
  },
  {
    title: "Truck Toll Calculator",
    link: "https://tollguru.com/truck-toll-calculator",
  },
  {
    title: "Trip Calculator",
    link: "https://tollguru.com/trip-calculator",
  },
  {
    title: "EV Trip Planner",
    link: "https://tollguru.com/ev-trip-planner",
  },
  {
    title: "HOS and State Mileage Calculator",
    link: "https://tollguru.com/hos-calculator",
  },
];
---

<div class="relative min-h-72 flex items-center">
  <Video
    client:visible
    className="rounded w-full h-full object-cover absolute inset-0"
    icon="https://cdn.tollguru.com/tollguru_v2/video/landing/tollguru-toll-calculator-in-action-video.webm"
    iconMp4="https://cdn.tollguru.com/tollguru_v2/video/see-toll-calculator-in-action/mp4/tollguru-toll-calculator-in-action-video.mp4"
  />

  <div class="absolute h-full inset-0 bg-black opacity-60 z-10"></div>
  <div class="px-5 relative z-10 inset-0 h-auto p-20 max-w-screen-xl mx-auto">
    <div
      class:list={[
        "max-w-4xl mx-auto flex flex-col items-center justify-center",
      ]}>
      <h2
        tabindex={0}
        aria-label="See our Toll Calculators in Action"
        class="text-3xl lg:text-4xl font-bold lg:tracking-tight mt-4 text-center text-white">
        See Our <span class="bg-text-gradient text-transparent bg-clip-text"
          >Toll Calculators
        </span> in Action
      </h2>
      <p 
        aria-label=`Compare multiple routes by cost and time trade-offs to find your optimal
        route. Know the costs (toll, fuel costs, other costs) and time for any
        route across North America, Latin America, Europe (including Russian
        Federation), Oceania (Australia, New Zealand), and Asia (India, Turkey,
        Japan). Cheapest & legal truck routes with HAZMAT, height, weight, axles
        & other restrictions.`
        tabindex={0}
        class="text-lg mt-4 text-slate-200 text-center [text-wrap:pretty]">
        Compare multiple routes by cost and time trade-offs to find your optimal
        route. Know the costs (toll, fuel costs, other costs) and time for any
        route across North America, Latin America, Europe (including Russian
        Federation), Oceania (Australia, New Zealand), and Asia (India, Turkey,
        Japan). Cheapest & legal truck routes with HAZMAT, height, weight, axles
        & other restrictions.
      </p>
    </div>

    <div class="flex flex-wrap justify-center mt-16 gap-6 md:gap-6">
      {
        features.map((item) => (
          <a href={item.link}>
            <div class="flex-shrink-0 relative px-5 py-2 overflow-hidden border shadow-md place-items-center rounded-md bg-tollguru-light-alt-2 hover:bg-text-gradient shadow-gray-900/20 hover:text-white hover:border-transparent group">
              <p class="block font-sans antialiased leading-snug tracking-normal text-md text-slate-600 group-hover:text-white">
                {item.title}
              </p>
            </div>
          </a>
        ))
      }
    </div>
  </div>
</div>
